import React, { useEffect } from "react";
import SpotlightBanner from "components/SpotlightBanner";
import DepartmentSlider from "./DepartmentSlider";
import OfferSection from "./OfferSection";
import PreviousBuysSection from "./PreviousBuysSection";
import TestimonialsSection from "./TestimonialsSection";
import AddSection from "./AddSection";
import { useDispatch, useSelector } from "react-redux";
import EcomDisabledOfferSection from "./EcomDisabledOfferSection";
import DownloadOurApp from "./DownloadOurApp";
import SingleBanner from "components/SingleBanner";
import Promotion from "./Promotion";
import PromotionSection from "./PromotionSection";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";
import { getOffersWithProductData } from "redux/actions/homepage";
import OfferBanner from "./OfferBanner/OfferBanner";
import MultiBanner from "./MultiBanner/MultiBanner";
import TwoBannerSection from "./TwoBannerSection/TwoBannerSection";
import DealOfWeek from "./DealOfWeek";
import DepartmentSliderTheme2 from "./DepartmentSliderTheme2/index";
import FeaturedProducts from "./FeaturedProducts";
import WhatOnSaleProducts from "./WhatOnSaleProducts";
import DescoverSection from "./DescoverSection/DescoverSection";
import EcomDisabledOfferSectionTheme2 from "./EcomDisabledOfferSectionTheme2";
import CampaignSection from "./CampaignSection";
// import DepartmentCategory from "routes/DepartmentCategory";
import FocusDepartment from "routes/FocusDepartment";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const clientId = getLocalStorageValue("RSAclient-id");
  const memberNumber = getLocalStorageValue("member-number");
  const isLocalStorage = getLocalStorageValue("is_clear_localstorage");
  const { isUserLoggedIn } = useSelector(({ user }) => user);
  const { theme_type, is_clear_localstorage } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getOffersWithProductData(body));
  }, [storeId]); // eslint-disable-line

  const {
    ecom_section: { allow_ecommerce = "" },
  } = useSelector(({ common }) => common.commonDetailsData);

  const { has_access_home_page } = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  // clearing localstorage based on the value is_clear_localstorage - from backend
  // Madhu - 10/06/2024
  // 2024-07-17 condition added and else part write
  useEffect(() => {
    if (is_clear_localstorage !== isLocalStorage && isLocalStorage !== null) {
      localStorage.clear();
      setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
      window.location.reload();
    } else {
      setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
    }
  }, [isLocalStorage, is_clear_localstorage]);

  useEffect(() => {
    if (has_access_home_page == "0") {
      if (allow_ecommerce == "1") navigate("/search/products");
      else if (allow_ecommerce == "0") navigate("/coupons");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allow_ecommerce, has_access_home_page]);

  return (
    <>
      <SpotlightBanner />
      <SingleBanner />
      <DownloadOurApp />
      {/* <DepartmentCategory /> */}
      <FocusDepartment />
      {theme_type === "1" ? <DepartmentSlider /> : ""}

      <PromotionSection />
      {theme_type === "2" ? (
        <>
          {allow_ecommerce === "1" ? (
            <>
              <WhatOnSaleProducts />
              <DescoverSection />
              <FeaturedProducts />
              <OfferBanner />
              <DealOfWeek />

              {/* <Promotion /> */}
              <TwoBannerSection />
              <CampaignSection />
              <MultiBanner />
              <DepartmentSliderTheme2 />
            </>
          ) : (
            <>
              <EcomDisabledOfferSectionTheme2 />
            </>
          )}
        </>
      ) : (
        <>
          {allow_ecommerce === "1" ? (
            <OfferSection />
          ) : (
            <EcomDisabledOfferSection />
          )}
        </>
      )}

      <AddSection />
      {isUserLoggedIn && <PreviousBuysSection />}

      {/* <TestimonialsSection /> */}
    </>
  );
};

export default HomePage;
