import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import AdsSection from "./AdsSection";
import { getWeeklyad, resetSearch } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

import "./weekly-ads.scss";
import AdsSectionTheme2 from "./AdsSectionTheme2";

const WeeklyAds = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === storeId
    );
  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      StoreName: selectedStore?.ClientStoreName
    };
    dispatch(getWeeklyad(body));
  }, [dispatch]); // eslint-disable-line

  const weeklyAdsData = useSelector(({ weeklyad }) => weeklyad.weeklyadData.data);

  const adsSection = useSelector(({ weeklyad }) => weeklyad.weeklyadData.ads_section);

  const breadcrumbList = [{ lable: "Weekly Ads", link: "", searchTag: "" }];

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Weekly Ads" />
      {theme_type === "1" ?
        <AdsSection weeklyAdsDetails={weeklyAdsData} />
        :
        <AdsSectionTheme2 weeklyAdsDetails={weeklyAdsData} adsSectionDetails={adsSection} />
      }
    </>
  );
};
export default WeeklyAds;
