import React, { useEffect } from "react";
import ProductImage from "../../assets/images/preview-product.png";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import "./reward-details.scss";
import RewardDetailsItem from "./RewardDetailsItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getGetQualifiedRewardTiersV2Details,
  getPointsBasedRewardDetails,
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import { useLocation } from "react-router-dom";

const RewardDetails = () => {
  const breadcrumbList = [{ lable: "Rewards Detail", link: "", searchTag: "" }];
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const { PointsBasedRewardData } = useSelector(({ reward }) => reward);
  const { QualifiedRewardTiersV2Data } = useSelector(({ reward }) => reward);

  const location = useLocation();
  const { lmRewardId, Redeem, isPointBased, lmRewardData } = location.state;

  const RewardDetailsData = Redeem
    ? QualifiedRewardTiersV2Data?.RewardDetails
    : PointsBasedRewardData?.RewardDetails;
  const RewardTiersData = Redeem
    ? QualifiedRewardTiersV2Data?.QualifiedRewardTiers
    : PointsBasedRewardData?.RewardTiers;

  const LM_Reward_ID = RewardDetailsData?.LMRewardID;
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");

  const ImageURL = isPointBased
    ? lmRewardData?.RewardImageURL
    : lmRewardData?.image_url;
  const MemberPointsString = isPointBased
    ? RewardDetailsData?.MemberPointsString
    : lmRewardData?.PurchasedAmountString;
  const RewardPointsText = isPointBased
    ? RewardDetailsData?.RewardPointsText
    : lmRewardData?.RewardPurchasedAmountText;
  const Title = isPointBased ? RewardDetailsData?.Title : lmRewardData?.title;

  useEffect(() => {
    let body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      member_number: isUserLoggedIn ? memberNumber : "42115480278",
      lm_reward_id: lmRewardId,
      user_token: isUserLoggedIn ? userToken : "",
      AppName: isUserLoggedIn ? "shop" : "",
    };

    if (isPointBased) {
      if (Redeem) {
        dispatch(getGetQualifiedRewardTiersV2Details(body));
      } else {
        dispatch(getPointsBasedRewardDetails(body));
      }
    }
  }, [
    Redeem,
    clientId,
    dispatch,
    isUserLoggedIn,
    lmRewardId,
    memberNumber,
    storeId,
    userToken,
    isPointBased,
  ]);

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Rewards Point"} //Need to pass title through api res
      />
      <div className="detail-reward-main-wrap">
        <div className="container">
          <div className="detail-reward-box">
            <div className="detail-reward-img">
              <img src={ImageURL} alt="product_image" />
            </div>
            <div className="detail-reward-content-wrapper">
              <div className="detail-reward-range-bar">
                <div className="detail-reward-point">
                  <span>{MemberPointsString}</span>
                </div>
              </div>
              <div className="detail-reward-subtitle">{RewardPointsText}</div>
              <div className="detail-reward-title-btn">
                <div className="detail-reward-title">{Title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reward-details-wrap">
        <div className="container">
          <div className="reward-details-flex-item">
            {isPointBased ? (
              RewardTiersData?.map((eachRewardTier) => {
                return (
                  <RewardDetailsItem
                    eachRewardTier={eachRewardTier}
                    LM_Reward_ID={LM_Reward_ID}
                    Redeem={Redeem}
                  />
                );
              })
            ) : (
              <div>
                <div className="details-spend-reward-title">
                  <span>Additional Details</span>
                </div>
                <div className="spend-reward-details">
                  <span>{lmRewardData?.reward_details}</span>
                </div>
                <div className="spend-reward-numbers">
                  {lmRewardData?.valid_upcs}{" "}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardDetails;
