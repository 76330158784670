import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "components/ProductCard";
import ProductCardTheme2 from "components/ProductCardTheme2";
import Pagination from "components/Common/Pagination";
import { updateActivePage } from "redux/actions";
import { getLocalStorageValue, setLocalStorageValue } from 'config/helper';
import "./product-items.scss";


const ProductList = ({
  productList,
  activePageNo,
  productListByDepartmentTotalPages,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(activePageNo);

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  useEffect(() => {
    if (getLocalStorageValue("last_visited_page_no")) {
      dispatch(updateActivePage(+getLocalStorageValue("last_visited_page_no")));
      setPage(+getLocalStorageValue("last_visited_page_no"));
    } else if (activePageNo === 1) {
      dispatch(updateActivePage(1));
      setPage(1);
    }
  }, [dispatch, activePageNo]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    scrollToTop();
    setPage(data);
    dispatch(updateActivePage(data));
    setLocalStorageValue("last_visited_page_no", data);
  };

  return (
    <div className="deal-all-wrapper">
      <div className="product-list-grid">
        {theme_type === "1" && productList?
          (productList.map((product) => {
            return (
              <div className="product-list-grid-items" key={product.product_id}>
                <ProductCard product={product} />
              </div>
            );
          })):(
            productList.map((product) => {
            return (
              <div className="product-list-grid-items" key={product.product_id}>
                <ProductCardTheme2 product={product} />
              </div>
            );
          }))
        }
      </div>
      {productList.length ? (
        <Pagination
          pages={productListByDepartmentTotalPages}
          current={page}
          onClick={handleSetPage}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default ProductList;
