import React, { useEffect } from "react";
import Breadcrumb from "components/Common/Breadcrumb";
import ProductInfo from "./ProductInfo";
import ProductPreview from "./ProductPreview";
import RelatedProduct from "./RelatedProduct";
import { getProductDetails } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDepartmentTree, getRelatedProductList } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

const ProductDescription = () => {
  const dispatch = useDispatch();
  const { departmentSlug, productSlug } = useParams();
  const clientId = getLocalStorageValue("RSAclient-id");

  const { productDetails, departmentTree, relatedProductList } = useSelector(
    ({ product }) => product
  );

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const department =
    departmentTree &&
    departmentTree.find((slug) => slug.slug === departmentSlug);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    !departmentTree.length && dispatch(getDepartmentTree(body));
  }, [storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      slug: productSlug,
      category_ids: productDetails && productDetails.category_ids,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    productDetails && dispatch(getRelatedProductList(body));
  }, [department, storeId]); // eslint-disable-line

  const breadcrumbList = [
    {
      lable: department && department.name,
      link: `/departments/${departmentSlug}`,
      searchTag: "",
    },
    {
      lable: productDetails && productDetails.name,
      link: "",
      searchTag: "",
    },
  ];

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      slug: productSlug,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getProductDetails(body));
  }, [dispatch, productSlug, storeId]); // eslint-disable-line

  return (
    <>
      {department ? (
        <Breadcrumb breadcrumbList={breadcrumbList} />
      ) : (
        <div className="breadcrumb"></div>
      )}
      {productDetails && (
        <>
          <ProductPreview productDetails={productDetails} />
          <ProductInfo productDetails={productDetails} />

          {relatedProductList && relatedProductList.length && (
            <RelatedProduct relatedProductList={relatedProductList} />
          )}
        </>
      )}
    </>
  );
};
export default ProductDescription;
