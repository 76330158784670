import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddressSectionTheme2 from "./AddressSectionTheme2";
import SingleAddressTheme2 from "./SingleAddressTheme2";
import ConfirmationModalTheme2 from './ConfirmationModalTheme2'
import "./address-book.scss";
import { getLocalStorageValue } from 'config/helper';

import { getCustomerAddressList, deleteCustomerAdress } from "redux/actions";

const AddressBookTheme2 = () => {

  const dispatch = useDispatch();
  const [isShowAddress, setShowAddress] = useState(false);
  const [removeAddress, setremoveAddress] = useState(false);
  const [editAddress, seteditAddress] = useState(false);

  // const [modal, setModal] = useState(false);
  const Toggle = () => seteditAddress(!editAddress);
  const [contentDescription, setcontentDescription] = useState("");
  const [isShowConfirmModal, setShowConfirmModal] =
    useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState("");

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const customerAddressList = useSelector(
    ({ checkout }) => checkout.customerAddressList
  );


  const handleAddress = () => {
    setShowAddress(!isShowAddress);
  };

  const handleeditAddress = () => {
    seteditAddress(!editAddress);;
  }


  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCustomerAddressList(body));
  }, [dispatch, clientId, memberNumber, userToken, isShowAddress, removeAddress, editAddress]); // eslint-disable-line

  const handleRemoveAddress = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      address_id: selectedAddress,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    dispatch(deleteCustomerAdress(body)).then(() => {

      setTimeout(() => setremoveAddress(!removeAddress),
        1500
      );

    });
  };


  const handleShowConfirmationPopup = (addressList) => {
    setShowConfirmModal(true)
    setSelectedAddress(addressList.address_id)
  }

  return (
    <div className="address-book-section-theme2">
      <div className="page-title">
        <h1>Address Book</h1>
        {
          editAddress ?
            <button onClick={() => Toggle()}>Back To Address Book</button>
            :
            <button onClick={handleAddress}>{isShowAddress ? 'Back To Address Book' : 'Add'}</button>
        }

      </div>

      {isShowAddress && <AddressSectionTheme2 changeHandler={handleAddress} />}

      <ConfirmationModalTheme2
        isShow={isShowConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onClear={handleRemoveAddress}
      />

      {
        editAddress ?
          <SingleAddressTheme2 contentDescription={contentDescription} changeEditAddress={handleeditAddress} />
          :
          !isShowAddress && !editAddress && customerAddressList && customerAddressList.length > 0 ? (
            <div className="table-wrapper">
              <div className="scrollable">
                <table className="responsive">
                  <tbody>
                    <tr>
                      <th align="left">Address</th>
                      <th align="left">City</th>
                      <th align="left">Zip</th>
                      <th align="left">Contact</th>
                      {/* <th align="left">IS Primary</th> */}
                      {/* <th align="left">Type</th> */}
                      <th align="left"></th>
                    </tr>

                    {customerAddressList.map((addressList, index) => {


                      return (
                        <tr key={index}>
                          <td>
                            {addressList.address_1}, {addressList.address_2}
                          </td>
                          <td>{addressList.city}</td>
                          <td>{addressList.zip_code}</td>
                          <td>{addressList.contact}</td>
                          {/* <td>{addressList.is_primary === "1" ? "Yes" : "No"}</td> */}
                          {/* <td>{addressList.status}</td> */}
                          <td>
                            <div className="action-btn">


                              <button onClick={() => {
                                Toggle();
                                setcontentDescription(addressList);
                              }}
                              >Edit</button>

                              <button
                                className="delete"
                                onClick={() => handleShowConfirmationPopup(addressList)}
                              >

                                Remove
                              </button>
                            </div>
                          </td>
                        </tr>
                      );

                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            " "
          )
      }

    </div>


  );
};
export default AddressBookTheme2;
