import React from "react";
import "./download-checkout-invoice.scss";
import ProductImage from "../../../../assets/images/preview-product.png";
import Logo from "assets/logo/footer-logo.svg";
import { useSelector } from "react-redux";


const CheckoutInvoiceTheme2 = React.forwardRef((props, ref) => {

    const singleOrderData = useSelector(
        ({ customer }) => customer.orderdetailsData
    );

    const { rsa_client_logo } = useSelector(
        ({ common }) => common.commonDetailsData.header_section || {}
      );

    const dateFormat = (inputDate, format) => {
        const date = new Date(inputDate);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        format = format.replace("MM", month.toString().padStart(2, "0"));


        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2, 2));
        }
        format = format.replace("dd", day.toString().padStart(2, "0"));

        return format;
    }


    const formatPhoneNumber = (str) => {
        let cleaned = ('' + str).replace(/\D/g, '');

        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };



    return (
        <>
            {singleOrderData && (
                <div ref={ref}>
                    <div className="download-checkout-invoice-wrapper">
                        
                        <div className="oreder-details-title-list">
              <div className="order-details-page-title">
                <h1>Order Details</h1>
                {/* <button onClick={handleAddNewAddress}>Add</button> */}
                <span>
                  {Object.keys(singleOrderData).length > 0
                      ? dateFormat(
                          singleOrderData.order_placed_on.split(" ")[0],
                          "MM-dd-yyyy"
                        )
                      : ""}</span>
                <span>4 Products</span>
              </div>
            </div>
                    </div>

                    <div className="download-invoice-body-wrapper">
                    <div className="order-card">
              <div className="order-item-list-wrapper">
                <div className="order-item-list">
                  <div className="order-address-items">
                    <div className="order-address-book">
                      <div className="address-title">
                        billing address
                      </div>
                      <div className="address-description">
                        <div className="user-name info">
                        {singleOrderData.shipping_method_name}
                        </div>
                        <div className="user-email-box info">
                          email
                          <span>demo@gmail.com</span>
                        </div>
                        <div className="user-phone-box info">
                          phone
                          <span>{formatPhoneNumber(singleOrderData.StorePhoneNumber)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-address-items">
                    <div className="order-address-book">
                      <div className="address-title">
                        Shipping address
                      </div>
                      <div className="address-description">
                        <div className="user-name info">
                        {singleOrderData.customer_first_name}{" "}
                    {singleOrderData.customer_last_name}
                        </div>
                        <div className="user-email-box info">
                          email
                          <span>{singleOrderData.customer_email}</span>
                        </div>
                        <div className="user-phone-box info">
                          phone
                          <span>{formatPhoneNumber(singleOrderData.order_perferred_mobile_number)}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="order-steps">
                  <div className="payment-title">
                    <span className="order-id">ORDER ID : <span>{singleOrderData.order_number}</span></span>
                    <span className="method-title">PAYMENT METHOD : <span>{singleOrderData.payment_method_name}</span></span>
                  </div>
                  <div className="order-pyment-steps">
                    <p>Subtotal:
                      <span>{`$${singleOrderData.amount}`}</span>
                    </p>
                    <p>Additional Authorized Amount
                      <span>{`$${
                        singleOrderData.additional_authorization_amount
                          ? singleOrderData.additional_authorization_amount
                          : "0.00"
                      }`}</span>
                    </p>
                    <p>Shipping:
                      <span>{singleOrderData.shipping_method_name}</span>
                    </p>
                    <h6>Total:
                      <span>{`$${singleOrderData.total_amount}`}</span>
                    </h6>

                  </div>
                </div>
              </div>
            </div>
                    </div>
                    <div className="download-invoice-table-wrapper">
                    <div class="product-table">
                <table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Object.keys(singleOrderData).length > 0 &&
                    singleOrderData.order_item_list.map(
                      (order_item_list, index) => {
                        const unitPrice = order_item_list.price
                          ? +order_item_list.price
                          : 0;
                        const totalAmount = order_item_list.row_total
                          ? +order_item_list.row_total
                          : 0;
                        return (
                          <tr key={index}>
                      <td>
                        <div className="product-image-wrap">
                          
                        <span class="product-image">
                          <img src={ProductImage} alt="product image" />

                        </span>
                        <div className="product-title">{order_item_list.name}</div>

                        </div>

                      </td>
                      <td>${+unitPrice.toFixed(2) ?? "0.00"}</td>
                      <td>{order_item_list.qty}</td>
                      <td>${totalAmount.toFixed(2) ?? "0.00"}</td>
                      </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
                    </div>
                </div>
            )}

        </>
    );
});

export default CheckoutInvoiceTheme2;
