import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageValue } from 'config/helper';
import CouponCard from "components/CouponCard";
import CouponCardTheme2 from "components/CouponCardTheme2";
import Pagination from "components/Common/Pagination";
import "./coupon-items.scss";
import {
  getcouponcartlist
} from "redux/actions";


const CouponList = ({ couponListsData }) => {
  const [page, setPage] = useState(1);
  const couponDataLoading = useSelector(({ coupon }) => coupon.couponDataLoading);
  const pageLimit = 20
  useEffect(() => {
    setPage(1);
  },[couponListsData])
  const totalPages = Math.ceil(couponListsData.length / pageLimit)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    scrollToTop();
    setPage(data);
  };


  const offset = (page - 1) * pageLimit;
  const updatedCouponListsData = couponListsData?.slice(offset, offset + pageLimit);


  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const [renderCouponlist, setRenderCouponlist] = useState(false);

  const couponCartlist = () => {
    setRenderCouponlist(!renderCouponlist)
  }

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: "shop",
      member_number: memberNumber,
      user_token: userToken,
      Version: "1",
      DeviceType: "web",
    };
    isUserLoggedIn &&
      dispatch(getcouponcartlist(body))
  }, [renderCouponlist]); // eslint-disable-line


  return (
    <div>
      <div className="coupon-list-grid">
        {updatedCouponListsData &&
          updatedCouponListsData.map((coupon) => {
            return (
              <div className="coupon-list-grid-items">
                {(theme_type === "2")?(
                  <CouponCardTheme2 coupon={coupon} handleCouponcartList={couponCartlist} />
                ):(
                  <CouponCard coupon={coupon} handleCouponcartList={couponCartlist} />
                )}
              </div>
            );
          })}

      </div>
      {!couponDataLoading && couponListsData.length  > 0  ? (
        <Pagination
          pages={totalPages}
          current={page}
          onClick={handleSetPage}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default CouponList;
