import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import SearchProducts from "./SearchProducts";
import StoreLocation from "./StoreLocation";
import Wishlist from "./Wishlist";
import ShoppingCart from "./ShoppingCart";
import MyAccount from "./MyAccount";
import MobileSidebar from "./MobileSidebar";
import Coupon from "../../../assets/icons/coupon.png";

import "./main-header.scss";

const MainHeader = () => {
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const allow_ecommerce = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce
  );
  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  return (
    <>
      <div className="main-header">
        <div className="container">
          <div className="grid">
            <div className="grid-items mobile-bar-icon">
              <div
                className="mobile-menu"
                onClick={() => setMobileSidebar(!mobileSidebar)}
              >
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
            <div className="grid-items">
              <SearchProducts />
              <div className="stickey-logo">
                {headerSectionData && headerSectionData.rsa_client_logo ? (
                  <NavLink to="/">
                    <img
                      src={headerSectionData.rsa_client_logo}
                      alt={headerSectionData.image_alt_text}
                    />
                  </NavLink>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="grid-items mobile-location">
              <div className="mobile-store-location">
                <StoreLocation />
              </div>
              <div className="main-header-middle-grid">
                <div className="main-header-middle-grid-items">
                  <StoreLocation />
                </div>
                <div className="main-header-middle-grid-items">
                  <SearchProducts />
                </div>
              </div>
            </div>
            <div className={"grid-items-flex-end grid-items"}>
              <div className="header-shop-btn">
                <a href="/departments">Shop</a>
              </div>
              <NavLink className="add-to-list-items" to="/list-items">
                <Wishlist />
              </NavLink>
              {isUserLoggedIn && (
                <div className="coupon-icon-wrapper">
                  <Link to="/coupon-view-cart" className="coupon-icon">
                    <img src={Coupon} alt="coupon" />
                  </Link>

                  <span className="form-control-validation-Coupon">
                    Clipped Coupon
                  </span>
                </div>
              )}
              {isUserLoggedIn ? (
                <>
                  <MyAccount />
                  {allow_ecommerce === "1" ? <ShoppingCart /> : ""}
                </>
              ) : (
                ""
              )}

              {/* <div
                className="mobile-menu"
                onClick={() => setMobileSidebar(!mobileSidebar)}
              >
                <i className="fa-solid fa-bars"></i>
              </div>  */}
              {!isUserLoggedIn ? (
                <div className="auth-links">
                  <ul>
                    <NavLink to="/signup">
                      <li>Sign Up</li>
                    </NavLink>
                    <NavLink to="/login">
                      <li>Sign In</li>
                    </NavLink>
                  </ul>
                </div>
              ) : (
                ""
              )}
              <div className="mobile-auth-links">
                {!isUserLoggedIn ? (
                  <ul>
                    <NavLink to="/login">
                      <i
                        className="fa-regular fa-circle-user"
                        aria-hidden="true"
                      ></i>
                    </NavLink>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileSidebar
        isMobileSidebar={mobileSidebar}
        onClose={() => setMobileSidebar(false)}
      />
    </>
  );
};

export default MainHeader;
