import React from "react";
import ProductImage from "../../../assets/images/preview-product.png";
import "./loyalty-reward.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const LoyaltyReward = ({ lmRewardData }) => {
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  return (
    <>
      <div className="loyalty-reward-main-wrap">
        <div className="loyalty-reward-box">
          <div className="loyalty-reward-img">
            <img src={lmRewardData?.image_url} alt="product_image" />
          </div>
          <div className="loyalty-reward-content-wrapper">
            <div className="loyalty-reward-subtitle">
              {lmRewardData?.RewardTitle}
            </div>
            <div className="loyalty-reward-title-btn">
              <div className="loyalty-reward-title">{lmRewardData?.title}</div>
              <div className="loyalty-reward-point-btn">
                <span>{lmRewardData?.PointsBasedRewardPointsToDollarInfo}</span>
              </div>
            </div>
            <div className="loyalty-reward-range-bar">
              <div className="loyalty-reward-point">
                <span>{lmRewardData?.PurchasedAmountString}</span>
              </div>
              <div className="reward-btn-wrapper">
                <div className="reward-btn">
                  <Link
                    to={isUserLoggedIn ? "/rewardsdetails" : "/login"}
                    state={{
                      lmRewardId: lmRewardData?.lm_reward_id,
                      isPointBased: lmRewardData?.is_points_based,
                      lmRewardData: lmRewardData,
                    }}
                  >
                    View Details
                  </Link>
                </div>
                {isUserLoggedIn && lmRewardData?.is_points_based && (
                  <div className="reward-btn redeem-btn">
                    <Link
                      to="/rewardsdetails"
                      state={{
                        lmRewardId: lmRewardData?.lm_reward_id,
                        Redeem: true,
                        isPointBased: lmRewardData?.is_points_based,
                        lmRewardData: lmRewardData,
                      }}
                    >
                      Redeem
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoyaltyReward;
