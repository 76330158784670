import React from "react";
import ProductImage from "../../../assets/images/preview-product.png";
import "./reward-details-item.scss";
import { Link } from "react-router-dom";

const RewardDetailsItem = ({ eachRewardTier, LM_Reward_ID, Redeem }) => {
  const LM_Reward_Tier_ID = eachRewardTier?.LMRewardTierID;

  return (
    <>
      <div className="reward-details-main-wrap">
        {Redeem && Redeem === true && (
          <div className="product-checkbox">
            <input type="checkbox" id="List 1" />
            <label for="List 1"></label>
          </div>
        )}

        <div className="reward-details-box">
          <div className="reward-details-img">
            <img src={eachRewardTier?.TierImageUrl} alt="product_image" />
          </div>
          <div className="reward-detail-items">
            <div className="reward-details-content-wrapper">
              <div className="reward-details-point">
                <span>{eachRewardTier?.PointsRequiredString}</span>
              </div>
              <div className="reward-details-title-btn">
                <div className="reward-details-title">
                  {eachRewardTier?.TierTitle}
                </div>
              </div>
            </div>
            <div className="reward-details-range-bar">
              <div className="reward-btn">
                <Link
                  to="/rewardviewdetails"
                  state={{
                    LM_Reward_ID: LM_Reward_ID,
                    LM_Reward_Tier_ID: LM_Reward_Tier_ID,
                    eachRewardTier: eachRewardTier,
                  }}
                >
                  View Items
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardDetailsItem;
