import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductImage from "../../../assets/images/preview-product.png";
import "./reward-view-item.scss";
import RewardProductItem from "./RewardProductItem";
import { useDispatch, useSelector } from "react-redux";
import { getGetUPCTierProductsDetails } from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import { useLocation } from "react-router-dom";

const RewardViewItem = () => {
  const breadcrumbList = [
    { lable: "Reward Details View", link: "", searchTag: "" },
  ];
  const dispatch = useDispatch();
  const location = useLocation();

  const { LM_Reward_ID, LM_Reward_Tier_ID, eachRewardTier } = location.state;

  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const { RewardTierProducts } = useSelector(
    ({ reward }) => reward?.UpcTierProductsData
  );

  useEffect(() => {
    let body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      member_number: isUserLoggedIn ? memberNumber : "42115480278",
      lm_reward_id: LM_Reward_ID,
      LMRewardTierID: LM_Reward_Tier_ID,
      user_token: "daa115ca-d4ae-4387-8f35-82aca500ab2a",
      AppName: isUserLoggedIn ? "shop" : "",
    };
    dispatch(getGetUPCTierProductsDetails(body));
  }, [
    LM_Reward_ID,
    LM_Reward_Tier_ID,
    clientId,
    dispatch,
    isUserLoggedIn,
    memberNumber,
    storeId,
  ]);

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={`Reward Details View`}
      />
      <div className="reward-view-main-wrap">
        <div className="container">
          <div className="reward-view-box">
            <div className="reward-view-img">
              <img src={eachRewardTier?.TierImageUrl} alt="product_image" />
            </div>
            <div className="reward-view-title">{eachRewardTier?.TierTitle} </div>
            <div className="view-reward-point">
              <span>{eachRewardTier?.PointsRequiredString}</span>
            </div>
          </div>
        </div>
        <div className="details-reward-main-wrap">
          <div className="container">
            <div className="details-reward-items">
              <div className="reward-items-wrap">
                {RewardTierProducts?.map((rewardProductItem) => {
                  return (
                    <RewardProductItem rewardProductItem={rewardProductItem} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardViewItem;
