import React, { useEffect } from "react";
import "./singlebanner.scss";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getLocalStorageValue } from "config/helper";
import { Link } from "react-router-dom";
import { getBannerAndTestimonialsData } from "redux/actions";
import loader from "assets/images/loader.gif";

const SingleBanner = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getBannerAndTestimonialsData(body));
  }, []); // eslint-disable-line

  const { banner_section2: bannerData, banner_setting } = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData || {}
  );
  const { bannerAndTestimonialsDataLoading } = useSelector(
    ({ homepage }) => homepage
  );

  const { slider_speed = 0, autoslide = "0" } = banner_setting || {};

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: autoslide === "1",
    autoplaySpeed: +slider_speed,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {bannerAndTestimonialsDataLoading ? (
        <img
          style={{ margin: "auto", width: "100px", height: "50px" }}
          src={loader}
          alt=""
        />
      ) : bannerData && bannerData.length !== 0 ? (
        <div className="singlebanner-banner-section">
          <Slider {...settings}>
            {bannerData
              ? bannerData.map((el) => {
                  return (
                    <div key={el.slider_id}>
                      <div className="singlebanner-banner">
                        <div className="singlebanner-banner-img">
                          {el?.clickable_image_url ? (
                            el.is_link_clickable_image_url === "0" ? (
                              <Link to={el.clickable_image_url}>
                                <img src={el.front_image} alt="Single Benner" />
                              </Link>
                            ) : (
                              <a href={el.clickable_image_url}>
                                <img src={el.front_image} alt="Single Benner" />
                              </a>
                            )
                          ) : (
                            <img src={el.front_image} alt="Single Benner" />
                          )}
                        </div>
                        {el?.slider_title1 ||
                        el?.slider_title2 ||
                        el?.slider_title3 ||
                        el?.button_title ? (
                          <div className="singlebanner-banner-grid">
                            <div className="singlebanner-banner-grid-items">
                              {el?.slider_title1 && <h1>{el.slider_title1}</h1>}
                              {el?.slider_title2 && <h2>{el.slider_title2}</h2>}
                              {el?.slider_title3 && <p>{el.slider_title3}</p>}

                              {el?.button_title ? (
                                el.is_link === "0" ? (
                                  el.is_open_self === "0" ? (
                                    <button>
                                      <Link to={el.button_url}>
                                        {el.button_title}
                                      </Link>
                                    </button>
                                  ) : (
                                    <button>
                                      <Link
                                        to={el.button_url}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {el.button_title}
                                      </Link>
                                    </button>
                                  )
                                ) : el.is_open_self === "0" ? (
                                  <button>
                                    <a href={el.button_url}>
                                      {el.button_title}
                                    </a>
                                  </button>
                                ) : (
                                  <button>
                                    <a
                                      href={el.button_url}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      {el.button_title}
                                    </a>
                                  </button>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })
              : ""}
          </Slider>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default SingleBanner;
