import { useSelector } from "react-redux";
import discover from "assets/images/discover.png";
import visa from "assets/images/visa.png";
import pay1 from "assets/images/pay1.png";
import wic from "assets/images/wic.jpg";
import snap from "assets/images/snap.png";
import amex from "assets/images/amex.png";
import jcb from "assets/images/jcb.png";
import Facebook from "assets/icons/facebook.png";
import Twitter from "assets/icons/twitter.png";
import Pinterest from "assets/icons/pinterest.png";
import Instagram from "assets/icons/instagram.png";
import Youtube from "assets/icons/youtube.png";
import "./copy-right-section-theme-2.scss";

const CopyRightSectionTheme2 = () => {
  const copyRightSectionData = useSelector(
    ({ common }) => common.commonDetailsData.footer_section
  );

  const footerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.footer_section
  );

  const { facebook_url, twitter_url, instagram_url, youtube_url } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const allowEcommerce = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce
  );

  return (
    <div className="copyright-section">
      <div className="container">
        <div className="CoprRight">
          <div className="powered-by-all-align-content">
            <p>
              {copyRightSectionData && copyRightSectionData.copyright_title
                ? copyRightSectionData.copyright_title
                : ""}
            </p>
            <div className="payment">
              {allowEcommerce == '1' ? (
                <>
                  <div
                    className="preview"
                    style={{ display: "inline-block" }}
                  >
                    {footerSectionData.accept_card_details.accept_discover == "1" ?
                      (<img
                        src={discover}
                        alt="discover"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_visa == "1" ?
                      (<img
                        src={visa}
                        alt="visa"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_mastercard == "1" ?
                      (<img
                        src={pay1}
                        alt="pay1"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_amex == "1" ?
                      (<img
                        src={amex}
                        alt="amex"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_jcb == "1" ?
                      (<img
                        src={jcb}
                        alt="jcb"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_wic == "1" ?
                      (<img
                        src={wic}
                        alt="wic"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_snap == "1" ?
                      (<img
                        src={snap}
                        alt="snap"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                  </div>
                </>
              ) : ""}

            </div>

            <div className="social-media">
              <ul>
                {facebook_url && (
                  <li>
                    <a href={facebook_url} rel="noreferrer" target="_blank">
                      <span>
                        <img src={Facebook} alt="facebook image" />
                      </span>
                    </a>
                  </li>
                )}
                {twitter_url && (
                  <li>
                    <a href={twitter_url} rel="noreferrer" target="_blank">
                      <span>
                        <img src={Twitter} alt="twitter image" />
                      </span>
                    </a>
                  </li>
                )}
                {instagram_url && (
                  <li>
                    <a href={instagram_url} rel="noreferrer" target="_blank">
                      <span>
                        <img src={Instagram} alt="instagram image" />
                      </span>
                    </a>
                  </li>
                )}
                {youtube_url && (
                  <li>
                    <a href={youtube_url} rel="noreferrer" target="_blank">
                      <span>
                        <img src={Youtube} alt="pinterest image" />
                      </span>
                    </a>
                  </li>
                )}
                
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyRightSectionTheme2;
