import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";

import MenuIcon from "assets/icons/menu.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Link } from "react-router-dom";

const ShopByDepartment = ({ handleCloseMobileSider }) => {
  const [isShowShopByDepartment, setIsShowShopByDepartment] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsShowShopByDepartment(false));

  const ecomData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section
  );
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const campaignlistData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.campaign_list
  );
  const CampainData = campaignlistData ?
    campaignlistData.map((items) => {
      return (
        <Link
          to={`${items.cammpagin_slug}`}
          key={items.campaign_id}
          onClick={() => {
            setIsShowShopByDepartment(!isShowShopByDepartment);
            handleCloseMobileSider && handleCloseMobileSider();
          }}
        >
          <div className="grid-items">
            <div className="department-icon">
              <img src={items.campaign_banner_img} alt={items.slug} />
            </div>
            <div className="department-name">
              <p>{items.campaign_name}</p>
            </div>
          </div>
        </Link>

      );
    }) : '';

  return (
    <div className="shop-by-department" ref={ref}>
      <button
        className="invert-button"
        onClick={() => setIsShowShopByDepartment(!isShowShopByDepartment)}
      >
        <a>
        <img src={MenuIcon} alt="MenuIcon" />
        </a>
        <span>{ecomData.name}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 16 7"><path fill="currentColor" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0c.2.2.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/></svg>
      </button>
      <div
        className={
          isShowShopByDepartment
            ? "shop-by-department-menu shop-by-department-menu-show"
            : "shop-by-department-menu shop-by-department-menu-hidden"
        }
      >
        <div className="shop-by-department-menu-content">
          <div className="grid">
            {ecomData &&
              ecomData.sub_department.map((el) => {
                return (
                  <Link
                        to={(!isUserLoggedIn && el.slug === "list-items" ) ? '/login' :  (el.is_mealkit > 0 ? `/catering-meal/${el.slug}` : `/departments/${el.slug}`)}
                        key={el.category_ids}
                        onClick={() => {
                          setIsShowShopByDepartment(!isShowShopByDepartment);
                          handleCloseMobileSider && handleCloseMobileSider();
                        }}
                      >
                        <div className="grid-items">
                          <div className="department-icon">
                            <img src={el.image} alt={el.slug} />
                          </div>
                          <div className="department-name">
                            <p>{el.name}</p>
                          </div>
                        </div>
                      </Link>
                );
              })
            }
            <>
              {CampainData}
            </>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopByDepartment;
