import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "components/Common/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerOrderdetails } from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import ProductImage from "../../../assets/images/preview-product.png";
import "./order-theme2.scss";

const MyOrderTheme2 = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const {
    orderlistData,
    OrderStatusList,
    OrderDetailsListLimit,
    OrderDetailsTotalRecord,
    orderlistDataLoading,
  } = useSelector(({ customer }) => customer);

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const [page, setPage] = useState(1);

  const totalPages = Math.ceil(
    +OrderDetailsTotalRecord / +OrderDetailsListLimit
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      PageNo: page,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCustomerOrderdetails(body));
  }, [
    dispatch,
    clientId,
    memberNumber,
    userToken,
    storeId,
    isUserLoggedIn,
    page,
  ]);

  const dateFormat = (inputDate, format) => {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    format = format.replace("MM", month.toString().padStart(2, "0"));

    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    scrollToTop();
    setPage(data);
  };

  return (
    <>
      <div className="order-section-theme2">
        <div className="order-page-title">
          <h1>My Orders</h1>
          {/* <button onClick={handleAddNewAddress}>Add</button> */}
        </div>
        <div className="order-section-main-wrapper">
          {orderlistData && orderlistData.length > 0 ? (
            <div className="order-section-wrapper">
              {orderlistData.map((order, index) => {
                return (
                  <div className="order-section-items" key={index}>
                    <Link
                      to={`/order-details/${order.order_id}`}
                      key={order.order_id}
                      state={{ OrderStatusList: OrderStatusList }}
                    >
                      <div className="order-items">
                        <div className="order-title">
                          {order?.order_item_list
                            ?.map((orderItem) => orderItem.name?.substr(0, 15))
                            .join(", ")}
                        </div>
                        <div className="order-product-image">
                          {order?.order_item_list?.map((orderItem) => (
                            <div className="order-image">
                              <img src={orderItem?.image} alt="product_image" />
                            </div>
                          ))}
                        </div>
                        <div className="order-number-id">
                          <div className="order-num-title">
                            Order Number : <span>{order.order_number}</span>
                          </div>
                        </div>
                        <div className="order-product-month">
                          {dateFormat(
                            order.order_placed_on.split(" ")[0],
                            "MM-dd-yyyy"
                          )}
                          <span>{order?.order_item_list?.length} Products</span>
                        </div>
                      </div>
                      <div className="order-price-section">
                        <span>New order</span>
                        <span className="price">{`$${order.total_amount}`}</span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-order-message">
              {" "}
              {orderlistDataLoading ? "Please wait..." : "No Orders yet!"}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MyOrderTheme2;
