import React, { useState } from "react";
import FilterIcon from "assets/icons/filter-icon.svg";
import CloseIcon from "assets/icons/white-close.svg";
import CouponListingPageSidebar from "../Sidebar";
import "./actions.scss";
import { useSelector } from "react-redux";
import loader from "assets/images/loader.gif";

const CouponListingPageAction = ({
  totalCuponCount,
  onChange
}) => {

  const [isShowMobileFilter, setIsShowMobileFilter] = useState(false);
  const couponDataLoading = useSelector(({ coupon }) => coupon.couponDataLoading);
  
  return (
    <div>
      <div className="coupon-listing-action-section">
        <div className="listing-grid-wrapper">
          <div className="found-items">
            {!couponDataLoading && totalCuponCount > 0 && (
              <p>
                We found <span> {totalCuponCount} items </span>{" "}
                for you!
              </p>
            )}
            {!couponDataLoading && totalCuponCount === 0 && (
              <p>
                No coupons found
              </p>
            )}
            {couponDataLoading && (
              <div style={{ display: 'flex', alignItems: 'center' , justifyContent: "center"}}>
                <img style={{ width: '100px', height: '50px' }} src={loader} />
                <span style={{ marginLeft: "10px" }}>Loading, please wait....</span>
              </div>
            )}
            {totalCuponCount > 0 && (
              <p className="product-count-mobile-screen">
                <span> {totalCuponCount} items </span>{" "}
              </p>
            )}
          </div>

          <div
            className="mobile-filter-button"
            onClick={() => setIsShowMobileFilter(!isShowMobileFilter)}
          >
            <button>
              <img src={FilterIcon} alt="FilterIcon" />
              <span>Filter</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          isShowMobileFilter
            ? "mobile-filter mobile-filter-show"
            : "mobile-filter mobile-filter-hidden"
        }
      >
        <div className="mobile-filter-header">
          <p>Filter</p>
          <div
            className="filter-close"
            onClick={() => setIsShowMobileFilter(false)}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
        </div>
        <CouponListingPageSidebar
          onChange={onChange}
          isMobileView={true}
          setIsShowMobileFilter={() => setIsShowMobileFilter(false)}
        />
      </div>
    </div>
  );
};
export default CouponListingPageAction;
